import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.modules.css";
import * as Analytics from "./analytics";
import * as Sentry from "@sentry/react";

Analytics.init();
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://49ddd2a984ba4b1ba9a902d521fbdc11@o421558.ingest.sentry.io/5341534",
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
