import React, { useReducer, useContext } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import gql from "graphql-tag";

const httpLink = createHttpLink({
  uri: "https://prod-mailmentor-api.herokuapp.com/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-access-token": accessToken,
      "x-refresh-token": refreshToken,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

interface Props {
  children: any;
}

interface User {
  id: number;
}

interface Session {
  user: User;
}

interface State {
  data?: Session;
  loading: boolean;
}

type ActionPaylod = User;

interface Action {
  type: string;
  payload: ActionPaylod;
}

interface ContextProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const initialState: State = { data: undefined, loading: false };

export const SessionCtxt = React.createContext({
  state: initialState,
  dispatch: () => {},
} as ContextProps);

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        data: { user: action.payload as User },
        loading: false,
      };
    default:
      throw new Error("Session reducer action not supported");
  }
};

export const Provider = function ({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  //const { data, loading, error } = useQuery(CURRENT_USER, {
  //client: client,
  //});

  //useEffect(() => {
  //if (loading !== undefined && error !== undefined) {
  //dispatch({ type: "UPDATE", payload: data });
  //}
  //}, [data]);

  // get current user
  //
  return (
    <SessionCtxt.Provider value={{ state, dispatch }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </SessionCtxt.Provider>
  );
};

export const useSession = function () {
  const { state, dispatch } = useContext(SessionCtxt);

  return { state, dispatch };
};

export const LOGIN_QUERY = gql`
  mutation {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const CREATE_USER = gql`
  mutation {
    createUser(
      email: $email
      password: $password
      passwordConfirm: $passwordConfirm
    )
  }
`;

export const CURRENT_USER = gql`
  query {
    loggedInUser {
      id
      accountId
      email
      firstName
      lastName
      account
      createdat
      updatedat
    }
  }
`;

export const COMPOSITIONS = gql`
  query {
    compositions {
      id
      body
    }
  }
`;

export const CREATE_COMPOSITION = gql`
  mutation {
    createComposition(body: $body) {
      id
      body
    }
  }
`;
