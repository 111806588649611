import React from "react";
import * as processor from "../../processor";
import { ReactComponent as LockIcon } from "../../assets/icons/lock-closed.svg";
import * as Analytics from "../../analytics";
import { v4 as uuidv4 } from "uuid";

interface StatProps {
  stat: number;
  max: number;
  description: string;
}

const Stat = ({ stat, max, description }: StatProps) => {
  const coloring = ["text-3xl", "font-extrabold"];
  if (stat > max) {
    coloring.push("text-red-600");
  } else {
    coloring.push("text-green-600");
  }

  return (
    <>
      <h3 className={coloring.join(" ")}>{stat}</h3>
      <span>{description}</span>
    </>
  );
};

interface NotesProps {
  config: processor.Config;
  description?: processor.Description;
  userTier: processor.PlanTiers;
  onRecoClick?(): boolean;
  onRecoHover?(i: number | undefined): void;
}

// https://tailwindcss.com/resources/#app
export default ({
  config,
  description,
  userTier,
  onRecoClick,
  onRecoHover,
}: NotesProps) => {
  if (!description) {
    return null;
  }

  const hdlClickRecommendations = () => {
    Analytics.track("ClickRecommendation");
    if (onRecoClick) {
      onRecoClick();
    }
  };

  const UnlockedReco = function ({
    reco,
    idx,
  }: {
    reco: processor.Recommendation;
    idx: number;
  }) {
    return (
      <div
        className="w-full p-4 mb-3 bg-red-100 border border-l-8 border-green-400 rounded-lg shadow-md"
        onMouseEnter={() => {
          if (onRecoHover) {
            onRecoHover(idx);
          }
        }}
        onMouseLeave={() => {
          if (onRecoHover) {
            onRecoHover(undefined);
          }
        }}
      >
        <div>
          <h3 className="font-bold text-gray-900">{reco.recommendation}</h3>
          <span className="font-thin text-gray-900 italic">
            Expected: {reco.actual} of {reco.expected}
          </span>
        </div>
      </div>
    );
  };

  const LockedReco = function ({
    reco,
    idx,
  }: {
    reco: processor.Recommendation;
    idx: number;
  }) {
    return (
      <div
        className="w-full p-4 mb-3 bg-red-100 border border-l-8 border-red-400 rounded-lg shadow-md cursor-pointer"
        onClick={hdlClickRecommendations}
        onMouseEnter={() => {
          if (onRecoHover) {
            onRecoHover(idx);
          }
        }}
        onMouseLeave={() => {
          if (onRecoHover) {
            onRecoHover(undefined);
          }
        }}
      >
        <div>
          <LockIcon className="w-5 h-5 fill-current text-gray-400" />
        </div>
        <div>
          <h3 className="font-bold text-gray-400">Register To View</h3>
          <span className="font-thin text-gray-400 italic">
            Expected: {reco.actual} of {reco.expected}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="text-center text-gray-900 p-6">
        <h1 className="text-4xl font-extrabold">MailMentor.io</h1>
        <h3>A data driven approach to writing better email</h3>
      </div>
      <div className="flex shadow-md bg-gray-100 rounded-lg text-gray-600">
        <div className="flex-1 text-center p-4 border-r-2 border-gray-200">
          <Stat
            stat={description.readingTime.duration}
            max={config.maxReadingTime}
            description="Reading Time (secs)"
          />
        </div>
        <div className="flex-1 text-center p-4">
          <Stat
            stat={description.gradeLevel}
            max={config.maxGradeLevel}
            description="Reading grade Level"
          />
        </div>
      </div>
      <div className="mt-4">
        {description.recos.map((ele, idx) => {
          if (ele.minPlanTier <= userTier) {
            return <UnlockedReco reco={ele} idx={idx} key={uuidv4()} />;
          }
          return <LockedReco reco={ele} idx={idx} key={uuidv4()} />;
        })}
      </div>
    </>
  );
};
