import amplitude from "amplitude-js/amplitude";
import ReactGA from "react-ga";

export const init = () => {
  if (process.env.NODE_ENV === "production") {
    amplitude.getInstance().init("913e16458e7524b4183c881b3ef75fff");
    ReactGA.initialize("UA-57357039-2");
  }
};

export const page = (path?: string) => {
  if (path === undefined) {
    path = window.location.pathname + window.location.search;
  }
  if (process.env.NODE_ENV === "production") {
    ReactGA.pageview(path);
    amplitude.getInstance().logEvent("PageView", { path: path });
  } else {
    console.log("PAGE", path);
  }
};

export const identify = (email: string, props?: any) => {
  if (process.env.NODE_ENV === "production") {
    const amp = amplitude.getInstance();

    const identObj = new amp.Identify();
    // TODO: support props
    identObj.set("email", email);
    amp.identify(identObj);
  } else {
    console.log("IDENTIFY", email, props);
  }
};

export const track = (event: string, props?: any) => {
  if (process.env.NODE_ENV === "production") {
    amplitude.getInstance().logEvent(event, props);
    ReactGA.event({
      category: "General",
      action: event,
    });
  } else {
    console.log("TRACK", event, props);
  }
};
