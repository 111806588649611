import React, { useState, useEffect } from "react";
import * as Analytics from "./analytics";
import Modal from "./Modal";
import { TextField } from "./components";
import { DescriptionProvider } from "./Description";
import * as GraphQl from "./graphql";
import { ReactComponent as UnlockIcon } from "./assets/icons/lock-open.svg";

const placeholder: string = `Hi!

Welcome to MailMentor.io.

This is a tool to help you write cold emails using data.

On the right are various recommendations of how to improve the message you type here.

To get started, just start typing (or paste) the email you want to score.

Thanks
`;

//const isProduction = () => {
//return Config.environment == "production";
//};

//interface DriftParams {
//children: React.ReactChildren;
//}

//const GetDrift = ({ children }: DriftParams) => {
//if (!isProduction()) {
//return <React.Fragment>{children}</React.Fragment>;
//}

//return <React.Fragment>{children}</React.Fragment>;
//};

function App() {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalStep, setModalStep] = useState<number>(0);
  useEffect(() => {
    Analytics.page();
  }, []);

  const toggleModalVis = () => {
    const vis = !modalVisible;
    setModalVisible(vis);
    return vis;
  };

  const ModalContents = () => {
    const [email, setEmail] = useState<string | undefined>(undefined);
    const { dispatch } = GraphQl.useSession();
    const onFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    };
    const clickSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (email !== "" || !modalVisible) {
        setModalStep(modalStep + 1);
        Analytics.identify(email as string);
        Analytics.track("LeadCapture");
        dispatch({ type: "UPDATE", payload: { id: 1 } });
      }
    };

    if (modalStep === 0) {
      return (
        <div className="text-center">
          <h1>Not signed in!</h1>
          <p className="py-4">
            Enter your email address below to unlock this feature.
          </p>
          <form>
            <div className="mb-4">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="your@email.com"
                onChange={onFormChange}
                value={email}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                onClick={clickSubmit}
                onSubmit={clickSubmit}
              >
                Unlock Feature{" "}
              </button>
            </div>
          </form>
        </div>
      );
    }

    return (
      <div className="text-center">
        <UnlockIcon className="w-10 h-10 fill-current text-green-400" />
        <h1>Perfect! Access was just unlocked.</h1>
        <div className="flex items-center justify-between">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => {
              toggleModalVis();
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  };
  return (
    <GraphQl.Provider>
      <DescriptionProvider>
        <Modal visible={modalVisible} toggleModalVis={toggleModalVis}>
          <ModalContents />
        </Modal>
        <TextField placeholder={placeholder} onRecoClick={toggleModalVis} />
      </DescriptionProvider>
    </GraphQl.Provider>
  );
}

export default App;
