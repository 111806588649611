import React from "react";

interface ModalProps {
  visible: boolean;
  toggleModalVis: () => void;
  children?: React.ReactElement;
}

export default ({ visible, children, toggleModalVis }: ModalProps) => {
  let myClass = "";
  if (!visible) {
    myClass = myClass + "hidden";
  }
  return (
    <div className={myClass}>
      <div className="w-full h-full fixed m-0 p-10 bg-smoke-light z-40">
        <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-xl z-50">
          {children}
        </div>
      </div>
    </div>
  );
};
