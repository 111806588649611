import React, { useEffect, useState } from "react";
import * as processor from "../../processor";

interface HighlightsParams {
  text: string;
  recommendations: processor.Recommendation[];
  scrollTop: number;
  onRecoHover: (i: number | undefined) => void;
}

//https://codersblock.com/blog/highlight-text-inside-a-textarea/
export default function ({
  text,
  recommendations,
  scrollTop,
}: HighlightsParams) {
  //const [isIE, setIsIE] = useState(false);
  //const [isIOS, setIsIOS] = useState(false);
  const [isWinPhone, setIsWinPhone] = useState(false);
  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase();
    //setIsIE(!!ua.match(/msie|trident\/7|edge/));
    //setIsIOS(!isWinPhone && !!ua.match(/ipad|iphone|ipod/));
    setIsWinPhone(ua.indexOf("windows phone") !== -1);
  }, [isWinPhone]);

  // TODO: we really want to be able to pass character ranges here for hightlight
  text = text.replace(/\n$/g, "\n\n");

  const recoStarts = new Map<number, processor.Recommendation>();
  const recoEnds = new Map<number, processor.Recommendation>();
  for (let i = 0; i < recommendations.length; i++) {
    const currentReco = recommendations[i];
    if (currentReco.type === processor.RecommendationType.Overall) {
      continue;
    }

    if (!currentReco.start || !currentReco.end) {
      continue;
    }

    if (
      currentReco.start !== undefined &&
      recoStarts.get(currentReco.start) === undefined
    ) {
      const idx = currentReco.start as number;
      recoStarts.set(idx, currentReco);
    }

    if (
      currentReco.end !== undefined &&
      recoEnds.get(currentReco.end) === undefined
    ) {
      const idx = currentReco.end as number;
      recoEnds.set(idx, currentReco);
    }
  }

  let comps = [];
  let textSection = "";
  for (let i = 0; i < text.length; i++) {
    const startReco = recoStarts.get(i);
    const endReco = recoEnds.get(i);

    if (startReco === undefined && endReco === undefined) {
      textSection = textSection + text[i];
      continue;
    }

    if (startReco !== undefined) {
      comps.push(textSection);
      textSection = text[i];
    }

    if (endReco !== undefined) {
      let color = "bg-yellow-200";
      if (endReco.isHovered) {
        color = "bg-blue-200";
      }

      const myClassName = `text-transparent text-black ${color}`;
      textSection = textSection + text[i];
      comps.push(<mark className={myClassName}>{textSection}</mark>);
      textSection = "";
    }
  }

  //if (isIE) {
  //// IE wraps whitespace differently in a div vs textarea, this fixes it
  //newText = newText.replace(/ /g, " <wbr>");
  //}

  //if (isIOS) {
  //divClasses.push("px-3");
  //}

  return <>{comps}</>;
}
