import * as Moo from "moo";
import * as FleschKincaid from "flesch-kincaid";
import * as R from "ramda";

export enum RecommendationType {
  Overall,
  Copy,
}

export enum PlanTiers {
  Anonymous = 0,
  Registered,
}

export interface Recommendation {
  recommendation: string;
  start?: number;
  end?: number;
  expected?: number;
  actual?: number;
  isHovered?: boolean;
  type: RecommendationType;
  minPlanTier: PlanTiers;
}

interface ReadingTime {
  duration: number;
  units: string;
}

export interface Description {
  gradeLevel: number;
  recos: Recommendation[];
  readingTime: ReadingTime;
  updatedAt: number;
}

export interface Config {
  maxReadingTime: number;
  paraMaxSent: number;
  maxWordsPerSent: number;
  maxGradeLevel: number;
  readingWPM: number;
}

type AST = any[];

const getReadingLevel = (doc: string): number => {
  // TODO: this doesnt seemto be accurate
  let score = FleschKincaid.grade(doc);
  if (score < 0) {
    score = 0;
  }
  return Math.floor(score);
};

const getSentOffset = (inAst: AST): number[] => {
  const sent = inAst[1];
  const firstToken = sent[0][1];
  const lastToken = sent[sent.length - 1][1];

  const startOffset: number = firstToken.offset;
  const endOffset: number = lastToken.offset + lastToken.value.length;
  return [startOffset, endOffset];
};

const getParaOffset = (inAst: AST): number[] => {
  const sents = inAst[0][1];
  const firstSent = sents[0];
  const firstToken = firstSent[1];
  const lastSent = inAst[1][inAst[1].length - 1];
  const lastToken = lastSent[lastSent[1].length - 1][1];

  const startOffset: number = firstToken.offset;
  const endOffset: number = lastToken.offset + lastToken.value.length;
  return [startOffset, endOffset];
};

const analyze = (config: Config, inAst: AST): Recommendation[] => {
  // process right, process left

  let out: Recommendation[] = [];

  switch (inAst[0]) {
    case "WORD":
      // Word analysis
      return out;

    case "SENT":
      // Sentance analysis
      if (inAst[1].length > config.maxWordsPerSent) {
        const [startOffset, endOffset] = getSentOffset(inAst);
        const newReco = {
          start: startOffset,
          end: endOffset,
          recommendation: "Too many words in the sentence",
          expected: config.maxWordsPerSent,
          actual: inAst[1].length,
          type: RecommendationType.Copy,
          minPlanTier: PlanTiers.Registered,
        };

        out.push(newReco);
      }
      out = out.concat(analyze(config, inAst[1]));
      return out;

    case "PARA":
      // Paragraph analysis

      if (inAst.length > 0 && inAst[1].length > config.paraMaxSent) {
        const [startOffset, endOffset] = getParaOffset(inAst[1]);

        const newReco = {
          start: startOffset,
          end: endOffset,
          recommendation: "Too many sentences in paragraph",
          expected: config.paraMaxSent,
          actual: inAst[1].length,
          type: RecommendationType.Copy,
          minPlanTier: PlanTiers.Registered,
        };

        out.push(newReco);
      }

      out = out.concat(analyze(config, inAst[1]));
      return out;
    case "DOC":
      out = out.concat(analyze(config, inAst[1]));
      return out;
  }

  for (let i = 0; i < inAst.length; i++) {
    out = out.concat(analyze(config, inAst[i]));
  }

  return out;
};

export const process = async (
  config: Config,
  inDoc: string
): Promise<Description> => {
  inDoc = inDoc.toLowerCase();

  // const emoji = `/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/`

  const lex = Moo.compile({
    WORD: /['"’@A-Za-z0-9_-]+/,
    SPACE: /[ \t]+/,
    SENT_BOUNDARY: /[./!?]/,
    PUNC: /[[\].,/#!$%^&*;:{}=\-_`~()]/,
    // EMOJI: emoji, //TODO: setup regex emoji
    NEW_PARAGRAPH: { match: /[\n\r]+/, lineBreaks: true }, // HACK: technically not right since a new line will give a paragraph
  });

  lex.reset(inDoc);
  let wordCount = 0;
  const readingSpeed = config.readingWPM / 60; // 250 wpm, 60 seconds

  let doc: AST = ["DOC", []];
  var currentParagraph: AST = [];
  var currentSent: AST = [];

  // TODO: should pass values in here, also brea out the close methods for each type
  const closeParagraph = () => {
    if (currentSent.length > 0) {
      currentParagraph.push(["SENT", currentSent]);
    }

    // add paragraph to tree
    if (currentParagraph.length > 0) {
      doc[1].push(["PARA", currentParagraph]);
    }

    currentSent = [];
    currentParagraph = [];
  };

  // Parse
  while (true) {
    const token = lex.next();
    if (token === undefined) {
      closeParagraph();
      break;
    }

    switch (token.type) {
      case "WORD":
        currentSent.push(["WORD", token]);
        wordCount++;
        break;

      case "SENT_BOUNDARY":
        currentParagraph.push(["SENT", currentSent]);
        currentSent = [];
        break;

      case "NEW_PARAGRAPH":
        closeParagraph();
        break;
    }
  }

  const recos = analyze(config, doc);
  const readingTime = {
    duration: Math.floor(wordCount / readingSpeed),
    units: "seconds",
  };
  const gradeLevel = getReadingLevel(inDoc);

  if (readingTime.duration > config.maxReadingTime) {
    recos.push({
      recommendation: "Shorten reading time",
      expected: config.maxReadingTime,
      actual: readingTime.duration,
      type: RecommendationType.Overall,
      minPlanTier: PlanTiers.Anonymous,
    });
  }

  if (gradeLevel > config.maxGradeLevel) {
    recos.push({
      recommendation: "Grade level too high.",
      expected: config.maxGradeLevel,
      actual: gradeLevel,
      type: RecommendationType.Overall,
      minPlanTier: PlanTiers.Anonymous,
    });
  }

  return {
    readingTime,
    gradeLevel,
    recos: R.sort(sortRecos, recos),
    updatedAt: Date.now(),
  };
};

const sortRecos = (a: Recommendation, b: Recommendation) => {
  if (
    a.type === RecommendationType.Overall ||
    a.start === undefined ||
    b.type === RecommendationType.Overall ||
    b.start === undefined
  ) {
    return -1;
  }

  if (a.start < b.start) {
    return -1;
  }
  return 0;
};
