import React, { useReducer } from "react";
import * as Processor from "./processor";

interface State {
  data?: Processor.Description;
  loading: boolean;
}

type HoveredPayload = number | undefined;

interface Action {
  type: string;
  payload: Processor.Description | HoveredPayload;
}

interface ContextProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const initialState = {
  data: {
    updatedAt: 0,
    gradeLevel: 0,
    readingTime: { duration: 0, units: "seconds" },
    recos: [],
  },
  hovered: undefined,
  loading: true,
};

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "HOVER_RECO":
      if (state.data === undefined) {
        return {
          ...state,
        };
      }
      const newRecos = [];

      for (let i = 0; i < state.data.recos.length; i++) {
        const reco = state.data.recos[i];
        if (action.payload === undefined) {
          reco.isHovered = false;
        }

        if (i === action.payload) {
          reco.isHovered = true;
        }

        newRecos.push(reco);
      }

      return {
        ...state,
        data: {
          ...state.data,
          recos: newRecos,
        },
      };
    case "UPDATE":
      return {
        data: action.payload as Processor.Description,
        loading: false,
      };
    default:
      throw new Error();
  }
};

export const DescriptionContext = React.createContext({
  state: initialState,
  dispatch: () => {},
} as ContextProps);

export const DescriptionProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const val = { state, dispatch };
  return (
    <DescriptionContext.Provider value={val}>
      {props.children}
    </DescriptionContext.Provider>
  );
};
