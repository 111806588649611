import React, { useState, useEffect, useRef, useContext } from "react";
import * as processor from "../../processor";
import * as Analytics from "../../analytics";
import cx from "classnames";
import styles from "./index.module.css";
import Highlights from "../Highlights";
import Notes from "../Notes";
import * as devText from "./dev-text";
import { DescriptionContext } from "../../Description";
import * as Graphql from "../../graphql";

interface TextFieldParams {
  onRecoClick: () => boolean;
  placeholder: string;
}

export default ({ onRecoClick, placeholder }: TextFieldParams) => {
  let defaultFormVal = "";
  if (process.env.NODE_ENV === "development") {
    defaultFormVal = devText.devText;
  }
  const { state, dispatch } = useContext(DescriptionContext);
  const [formValue, setFormValue] = useState<string>(defaultFormVal);
  const [previousFormValue, setPreviousFormValue] = useState<string>("");
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const highlightRef = useRef(null);
  const [textAreaScrollTop, setTextAreaScrollTop] = useState(0);
  const { state: session } = Graphql.useSession();
  // TODO: fix ios iseues

  const handleChange = (e: any) => {
    setPreviousFormValue(formValue);
    setFormValue(e.target.value);
  };

  const config = {
    paraMaxSent: 3,
    maxWordsPerSent: 10,
    maxGradeLevel: 6,
    maxReadingTime: 45,
    readingWPM: 150,
  };

  useEffect(() => {
    Analytics.track("TextFieldLoaded");
  }, []);

  useEffect(() => {
    const t0 = Date.now();
    const processData = async () => {
      try {
        const description = await processor.process(config, formValue);
        dispatch({
          type: "UPDATE",
          payload: description,
        });
      } catch (e) {
        console.error(e);
      }
    };

    if (state.data === undefined) {
      return;
    }

    if (previousFormValue.localeCompare(formValue) === 0) {
      return;
    }

    // First text entry
    if (previousFormValue === "" && formValue !== "") {
      setFormValue(formValue.trim());
      Analytics.track("ComposeEmail", {
        lastUpdate: 0,
        messageLength: 0,
      });
    }

    // Track 5 minute
    if (lastUpdate !== 0 && t0 - lastUpdate >= 300000) {
      Analytics.track("ComposeEmail", {
        lastUpdate: t0 - lastUpdate,
        messageLength: formValue.length,
      });
    }

    // Only processing every 500ms
    if (state.data.updatedAt === 0 || t0 - lastUpdate > 500) {
      setPreviousFormValue(formValue);
      setLastUpdate(t0);
      processData();
    }
  }, [config, formValue, previousFormValue, lastUpdate, state, dispatch]);

  const textAreaClass = cx(
    styles.baseEntry,
    "absolute z-2 w-2/3 h-screen bg-transparent text-gray-500 p-12 text-xl"
  );

  const onRecoHover = (recoIdx: number | undefined) => {
    if (state.data === undefined) {
      return;
    }

    dispatch({
      type: "HOVER_RECO",
      payload: recoIdx,
    });
  };

  useEffect(() => {
    if (highlightRef != null && highlightRef.current != null) {
      //@ts-ignore for testing
      highlightRef.current.scrollTop = textAreaScrollTop;
    }
  }, [textAreaScrollTop]);

  const handleScroll = (e: any) => {
    setTextAreaScrollTop(e.target.scrollTop);
  };

  if (state.data === undefined) {
    return null;
  }

  let userTier = processor.PlanTiers.Anonymous;

  if (session.data !== undefined) {
    userTier = processor.PlanTiers.Registered;
  }

  return (
    <>
      <div
        ref={highlightRef}
        className="absolute w-2/3 h-screen z-1 whitespace-pre-wrap break-words text-gray-900 p-12 text-xl overflow-y-auto"
      >
        <Highlights
          text={formValue}
          recommendations={state.data.recos}
          onRecoHover={onRecoHover}
          scrollTop={textAreaScrollTop}
        />
      </div>
      <textarea
        placeholder={placeholder}
        className={textAreaClass}
        value={formValue}
        onChange={handleChange}
        onScroll={handleScroll}
      />
      <div className="absolute inset-y-0 right-0 w-1/3 inset-y-0 right-0 p-10 bg-white overflow-y-scroll h-screen">
        <Notes
          onRecoHover={onRecoHover}
          onRecoClick={onRecoClick}
          config={config}
          description={state.data}
          userTier={userTier}
        />
      </div>
    </>
  );
};
